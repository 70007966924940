import { AnyAction, createAsyncThunk, ThunkAction } from '@reduxjs/toolkit';
import { GeoComplyType } from './types';
import { RootState } from 'sites/snai/lib/store';

type ReasonType = 'Login' | 'Deposit' | 'GamePlay' | 'Periodic';
// USED FOR LINTING

declare var GcHtml5: {
  createClient: () => {
    events: any;
    setLicense: (_license: string) => void;
    setUserId: (_userId: string) => void;
    setReason: (_reason: ReasonType) => void;
    request: () => void;
  };
};

let geoClient: ReturnType<typeof GcHtml5.createClient>;

const checkStatus = (geoComply: GeoComplyType) => {
  if (!geoComply) return false;
  if (!geoComply.license) {
    console.log('GeoComply license not found');
    return false;
  }
  if (!geoComply.isImported) {
    console.log('GeoComply not imported check the method');
    return false;
  }
  if (!geoClient) {
    console.log('geoClient not initialized');
    return false;
  }
  return true;
};

export const getGeoComplyLicense = createAsyncThunk(
  'getGeoComplyLicense',
  async (_params, { dispatch }): Promise<GeoComplyType> => {
    try {
      const response = await fetch(`api/geocomply/license`).then((res) => res.json());
      dispatch(instanceGeoComply(response.license));
      return Promise.resolve(response);
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

export const instanceGeoComply =
  (license: string): ThunkAction<void, RootState, unknown, AnyAction> =>
  (_dispatch, getState) => {
    const { geoComply } = getState();

    if (geoComply.isInitialized) throw new Error('GeoComply already initialized');

    geoClient = GcHtml5.createClient();
    geoClient.setLicense(license!);
  };

export const setUserID =
  (userID: string): ThunkAction<void, RootState, unknown, AnyAction> =>
  (_dispatch, getState) => {
    const { geoComply } = getState();
    if (!checkStatus(geoComply)) return;

    geoClient.setUserId(userID);
  };

export const requestGeoLocation =
  (reason?: ReasonType): ThunkAction<void, RootState, unknown, AnyAction> =>
  (dispatch, getState) => {
    const { geoComply } = getState();
    if (!checkStatus(geoComply)) return;

    if (reason) {
      geoClient.setReason(reason);
    }

    if (geoComply.license) geoClient.request();
  };
